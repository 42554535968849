import axios from "axios";

// const axiosInstance = (options) =>
//   axios.create({
//     baseURL: "http://localhost:30000",
//     headers: {
//       "Access-Control-Allow-Origin": "http://localhost:30000",
//     },
//     withCredentials: true,
//     ...options,
//   });
const axiosInstance = (options) => {
  const instance = axios.create({
    baseURL: "https://api.mamo-collagen.com",
    headers: {
      "Access-Control-Allow-Origin": "https://api.mamo-collagen.com",
      // ...(token && {"Authorization": `Bearer ${token}`})
    },
    withCredentials: true,
    ...options,
  });
  instance.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  });
  return instance;
};

const useAxiosInstance = axiosInstance();

export default useAxiosInstance;
