import React from "react";
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserPage from "views/User/User.js";
import { useAuthContext } from "context/auth-context";

export default function MainDashboard() {
  const { role } = useAuthContext();
  const getPage = () => {
    if (role === "admin") return <DashboardPage />;
    else if (role === "user") return <UserPage />;
  };
  return <>{getPage()}</>;
}
