/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import Logout from "@material-ui/icons/ExitToApp";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
// core components/views for Admin layout
import MainDashboardPage from "views/MainDashboard/MainDashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import TableList from "views/TableList/TableList.js";
import Typography from "views/Typography/Typography.js";
import Icons from "views/Icons/Icons.js";
import Maps from "views/Maps/Maps.js";
import NotificationsPage from "views/Notifications/Notifications.js";
import UserView from "views/UserView/UserView.js";
import LogoutPage from "views/Logout/Logout.js";
// core components/views for RTL layout

const onlyDevRoutes = [
  {
    path: "/table",
    name: "Table List",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    component: TableList,
    layout: "/admin",
  },
  {
    path: "/typography",
    name: "Typography",
    rtlName: "طباعة",
    icon: LibraryBooks,
    component: Typography,
    layout: "/admin",
  },
  {
    path: "/icons",
    name: "Icons",
    rtlName: "الرموز",
    icon: BubbleChart,
    component: Icons,
    layout: "/admin",
  },
  {
    path: "/maps",
    name: "Maps",
    rtlName: "خرائط",
    icon: LocationOn,
    component: Maps,
    layout: "/admin",
  },
  {
    path: "/notifications",
    name: "Notifications",
    rtlName: "إخطارات",
    icon: Notifications,
    component: NotificationsPage,
    layout: "/admin",
  },
];

let dashboardRoutes = [
  {
    path: "/dashboard",
    name: "ภาพรวม",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: MainDashboardPage,
    role: "admin",
    layout: "/admin",
  },
  {
    path: "/user/:id",
    name: "จัดการตัวแทน",
    show: false,
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: UserView,
    role: "admin",
    layout: "/admin",
  },
  {
    path: "/user",
    name: "โปรไฟล์ตัวแทน",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: UserProfile,
    role: "user",
    layout: "/admin",
  },
  {
    path: "/logout",
    name: "ออกจากระบบ",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Logout,
    component: LogoutPage,
    layout: "/admin",
  },
];
if (process.env.NODE_ENV === "development") {
  dashboardRoutes = [...dashboardRoutes, ...onlyDevRoutes];
}
export default dashboardRoutes;
