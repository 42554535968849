import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { getUserById, approveUser, deleteUser } from "service/api/user.js";
import avatar from "assets/img/faces/marc.jpg";
import Modal from "@material-ui/core/Modal";
import Close from "@material-ui/icons/Close";
import { hist } from "index";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: '"Kanit", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function UserProfile() {
  const { id } = useParams();
  const [type, setType] = useState()
  const classes = useStyles();
  const initialValue = {
    nickName: "",
    email: "",
    firstName: "",
    lastName: "",
    lineId: "",
    facebook: "",
    phone: "",
    address: "",
    province: "",
    postCode: "",
  };
  const [value, setValue] = useState(initialValue);
  const [isLoadingProfile, setIsLoadingProfile] = useState(true);
  const [src, setSrc] = useState(null);
  const nl2br = text =>
  text
    .split(/(?:\r\n|\r|\n)/)
    .reduce(
      (res, frag, i, arr) => [
        ...res,
        frag,
        ...(i < arr.length - 1 && [React.createElement("br")])
      ],
      []
    );
  const [showModal, setShowModal] = useState(false);
  const handleAction = (type) => {
    setType(type)
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (!isLoadingProfile) {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        const fontSize = 60;
        context.drawImage(img, 0, 0, img.width, img.height);
        const font = `${fontSize}px Arial`;
        context.font = font;
        context.fillText(value.firstName, Math.max(20, 0), Math.max(100, 0));
        context.fillText(value.lastName, Math.max(20, 0), Math.max(200, 0));
        context.fillText(value.phone, Math.max(20, 0), Math.max(300, 0));
        context.fillText(value.lineId, Math.max(20, 0), Math.max(400, 0));
        context.fillText(value.facebook, Math.max(20, 0), Math.max(500, 0));
        setSrc(canvas.toDataURL("image/jpeg"));
      };
      img.src = process.env.PUBLIC_URL + "/card.png";
    }
  }, [isLoadingProfile, value]);
  useEffect(() => {
    (async () => {
      const response = await getUserById(id);
      setValue(v => ({
        ...v,
        ...response.data.data,
      }));
      setIsLoadingProfile(false);
    })();
  }, []);

  const handleApprove = async () => {
    try {
      setIsLoadingProfile(true);
      await approveUser(id);
      hist.push('/admin/dashboard')
    } catch(error) {
      alert('Error')
      console.log(error)
    } finally {
      setIsLoadingProfile(false);
    }
  };

  const handleReject = async () => {
    try {
      setIsLoadingProfile(true);
      await deleteUser(id);
      hist.push('/admin/dashboard')
    } catch(error) {
      alert('Error')
      console.log(error)
    } finally {
      setIsLoadingProfile(false);
    }
  };

  const typeName = (type) => {
    switch(type) {
      case 1:
        return 'การอนุมัติ'
      case 2:
        return 'การไม่อนุมัติ'
    }
  }
  const handleConfirm = () => {
    if (type === 1) {
      handleApprove()
    } else {
      handleReject()
    }
  }
  const validateForm = () => {
    let isValid = true;
    for (const key in initialValue) {
      console.log(key);
      if (
        !document.querySelector(`#profileForm [name=${key}]`).reportValidity()
      ) {
        isValid = false;
        break;
      }
    }
    return isValid;
  };

  const handleInput = (e) => {
    const fieldName = e.target.name;
    setValue({
      ...value,
      [fieldName]: e.target.value,
    });
  };
  return (
    <div>
      <GridContainer id="profileForm">
      <Modal
        open={showModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100%",
          }}
        >
          <div
            style={{
              position: "relative",
              background: "white",
              maxWidth: 500,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              // alignItems: "center",
              padding: 20,
              borderRadius: 6,
            }}
          >
            <div
              className=""
              style={{
                position: "absolute",
                top: 20,
                right: 20,
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              <Close />
            </div>
            ยืนยัน{typeName(type)}
            <GridContainer>
              <GridItem>
                <Button
                  color="primary"
                  onClick={handleConfirm}
                  style={{ marginTop: 20 }}
                >
                  ยืนยีน
                </Button>
              </GridItem>
              <GridItem>
                <Button
                  color="primary"
                  onClick={handleClose}
                  style={{ marginTop: 20 }}
                >
                  ยกเลิก
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </Modal>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>โปรไฟล์ตัวแทน</h4>
              <p className={classes.cardCategoryWhite}>
                ตรวจสอบข้อมูลและอนุมัตื
              </p>
            </CardHeader>
            <CardBody  style={{ padding: 30}}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                <div style={{ minWidth: 120, display: 'inline-flex', fontWeight: 500}}>
                  อีเมล:
                </div>
                  {value.email}
                </GridItem>                
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <div style={{ minWidth: 120, display: 'inline-flex', fontWeight: 500}}>
                    ชื่อเล่น
                  </div>
                  {value.nickName}                  
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div style={{ minWidth: 120, display: 'inline-flex', fontWeight: 500}}>
                    ชื่อ-นามสกุล
                  </div>
                  {value.firstName + ' ' + value.lastName}                  
                </GridItem>                
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <div style={{ minWidth: 120, display: 'inline-flex', fontWeight: 500}}>
                    Line ID:
                  </div>
                  {value.lineId}
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div style={{ minWidth: 120, display: 'inline-flex', fontWeight: 500}}>
                    Facebook:
                  </div>
                  {value.facebook}
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div style={{ minWidth: 120, display: 'inline-flex', fontWeight: 500}}>
                    เบอร์โทรศัพท์
                  </div>
                  {value.phone}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <div style={{ minWidth: 120, display: 'inline-flex', fontWeight: 500}}>
                    ที่อยู่:
                  </div>                  
                  <div style={{display: 'inline-flex'}}>
                    {value.address.split('\n').map((item, key) => {
                      return <>{item}<br/></>
                    })}
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <div style={{ minWidth: 120, display: 'inline-flex', fontWeight: 500}}>
                    จังหวัด:
                  </div>
                  {value.province}
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div style={{ minWidth: 120, display: 'inline-flex', fontWeight: 500}}>
                    รหัสไปรษณีย์:
                  </div>
                  {value.postCode}
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <GridContainer>
                <GridItem>
                  <Button color="primary" onClick={() => {handleAction(1)}}>
                    อนุมัติ
                  </Button>
                </GridItem>
                <GridItem>
                  <Button color="danger" onClick={() => {handleAction(2)}}>
                    ไม่อนุมัติ
                  </Button>
                </GridItem>
              </GridContainer>
            </CardFooter>
          </Card>
        </GridItem>        
      </GridContainer>
    </div>
  );
}
