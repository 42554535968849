import React, { useEffect, useState } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import Close from "@material-ui/icons/Close";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { getOrderByUser, addOrder } from "service/api/order.js";
import { getStock, updateStock } from "service/api/userStock.js";
import Button from "components/CustomButtons/Button.js";
import Modal from "@material-ui/core/Modal";
import Edit from "@material-ui/icons/Edit";
// import { getUsers, getUserInDay } from "service/api/user.js";
import { getProduct } from "service/api/product.js";
import { bugs, website, server } from "variables/general.js";
import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
} from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const [orderData, setOrderData] = useState([]);
  const [stock, setStock] = useState(0);
  const [addingOrder, setAddingOrder] = useState(false);
  const [updatingStock, setUpdatingStock] = useState(false);
  const [numberToUpdate, setNumberToUpdate] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const handleEdit = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (!addingOrder || !updatingStock) {
      const fetchData = async () => {
        const mappedOrder = [];
        const response = await getStock();
        setStock(response.data?.data?.stock || 0);
        const orders = await getOrderByUser();
        orders.data.data.forEach((orders) => {
          const orderRow = [];
          orderRow.push("Collagen");
          orderRow.push("Me");
          orderRow.push(orders.quantity);
          orderRow.push(format(orders.createdDate));
          orderRow.push("Y");
          mappedOrder.push(orderRow);
        });
        setOrderData(mappedOrder);
      };
      fetchData();
    }
  }, [addingOrder, updatingStock]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const response = await getOrderInDay();
  //     setOrderCount(response.data.count);
  //   };
  //   fetchData();
  // }, []);
  const handleUpdateStock = async () => {
    setUpdatingStock(true);
    await updateStock(numberToUpdate);
    setUpdatingStock(false);
  };

  const handleAddOrder = async () => {
    setAddingOrder(true);
    await addOrder({ quantity });
    setAddingOrder(false);
  };
  function format(input) {
    var date = new Date(input);
    return (
      [
        ("0" + date.getDate()).slice(-2),
        ("0" + (date.getMonth() + 1)).slice(-2),
        date.getFullYear(),
      ].join("/") +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes()
    );
  }
  orderData.forEach((order) => console.log(order));
  const classes = useStyles();
  return (
    <div>
      <Modal
        open={showModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100%",
          }}
        >
          <div
            style={{
              position: "relative",
              background: "white",
              maxWidth: 500,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              // alignItems: "center",
              padding: 20,
              borderRadius: 6,
            }}
          >
            <div
              className=""
              style={{
                position: "absolute",
                top: 20,
                right: 20,
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              <Close />
            </div>
            <CustomInput
              labelText="จำนวนสต๊อก"
              id="quantity"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "quantity",
                type: "number",
                onChange: (e) => setNumberToUpdate(e.target.value),
              }}
            />
            <Button
              color="primary"
              onClick={handleUpdateStock}
              style={{ marginTop: 20 }}
            >
              อัพเดต
            </Button>
          </div>
        </div>
      </Modal>
      <GridContainer>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>fefeeefe</p>
              <h3 className={classes.cardTitle}>{stock}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats} style={{ width: "100%" }}>
                <DateRange />
                อัพเดตล่าสุด
                <div
                  style={{ marginLeft: "auto", cursor: "pointer" }}
                  onClick={handleEdit}
                >
                  <Edit />
                </div>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <input type="number" onChange={(e) => setQuantity(e.target.value)} />
      <div onClick={handleAddOrder}>Add Order</div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="danger">
              <h4 className={classes.cardTitleWhite}>ประวัติการเบิกสินค้า</h4>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="danger"
                tableHead={[
                  "สินค้า",
                  "เบิกโดย",
                  "จำนวน",
                  "เวลา",
                  "Admin Checked",
                ]}
                tableData={orderData}
                ว
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
