import styles from './uploader.module.scss'
import React, { useState, useRef } from 'react'

const readFile = (fileObj) => new Promise((
  resolve, reject
) => {
  const reader = new FileReader()
  reader.onload = () => {    
    resolve(reader.result)                 
  }
  reader.onerror = (error) => {
    reject(error)
  }
  reader.readAsDataURL(fileObj)
})

export default function CustomUploader({onUpload = Function, onSuccess = Function, onFailure = Function}) {
  const allowedFileTypes = ['image/jpeg', 'image/png', 'image/webp']  
  const [isUploading, setIsUploading] = useState(false)  
  const [isDragOver, setIsDragOver] = useState(false)    
  const input = useRef()
  let tempFiles = []
  const handleDrag = (event) => {        
    event.stopPropagation()
    event.preventDefault()
    if (['dragover', 'dragenter'].includes(event.type)) {
      setIsDragOver(true)
    } else {
      setIsDragOver(false)
    }
    if (event.type === 'drop'){
      handleUpload(event)
    }
  }
  const handleClick = () => {
    input.current.click()
  }
  const handleUpload = async (event) => {         
    onUpload()
    setIsUploading(true)
    let selectedFile = event?.target?.files || event?.dataTransfer?.files;        
    for (let i in selectedFile) {
      if (selectedFile[i].size > 4 * 1024 *1024) {
        alert('กรุณาอัพโหลดไฟล์ไม่เกิน 4 MB')
      }
      if (selectedFile[i].type && allowedFileTypes.includes(selectedFile[i].type)) {        
        const file = await readFile(selectedFile[i])
        tempFiles.push({
          name: selectedFile[i].name,
          type: selectedFile[i].type,
          size: selectedFile[i].size,
          data: file
        })
      }
      if (i > 0) {
        break;
      }
    }            
    if(tempFiles.length) {                  
      onSuccess([
        ...tempFiles        
      ])
      tempFiles = []      
    } else {
      onFailure({
        reason: 'file is not support'
      })
    }
    input.current.value = ''
    setIsUploading(false)         
  }
  return (    
    <div className={
      `${styles.customUploader}
       ${isUploading ? styles.uploading : ''}
       ${isDragOver ? styles.dragOver : ''}
      `
    }>
      <div 
        className={styles.uploadArea}
        onDragEnter={handleDrag}
        onDragOver={handleDrag}
        onDragLeave={handleDrag}
        onDrop={handleDrag}
        onClick={handleClick}
      >
        <span className={styles.text}>          
          { isUploading 
            ? 'Uploading...' 
            : (
              isDragOver 
              ? 'Drop file here' 
              : 'ลากไฟล์มาวางที่นี่ หรือ คลิกเพื่อเปลี่ยนรูปโปรไฟล์'
              )
          }
        </span>
      </div>
      <input ref={input} accept={allowedFileTypes.join(',')} type="file" onChange={handleUpload}/>      
    </div>    
  )
}