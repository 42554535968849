// @material-ui/core components
import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import View from "@material-ui/icons/Visibility";
import Hidden from "@material-ui/icons/VisibilityOff";
import jwt from "jsonwebtoken";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import avatar from "assets/img/faces/marc.jpg";
import { signin } from "service/api/auth";
import { useAuthContext } from "context/auth-context";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: '"Kanit", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function Login() {
  const hist = useHistory();
  const { setRole, loading, role } = useAuthContext();
  const [showPassword, setShowPassword] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const handleInput = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      hist.push("/admin");
    }
  }, []);

  const classes = useStyles();
  const handleLogin = async () => {
    try {
      const response = await signin({
        email: values.email,
        password: values.password,
      });
      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
        const { role: userRole } = jwt.decode(response.data.token);
        setRole(userRole);
        if (role === "user") {
          hist.push("/admin/user");
        } else {
          hist.push("/admin/dashboard");
        }
      }
    } catch (error) {
      console.log(error);
      alert("อีเมลหรือรหัสผ่านไม่ถูกต้อง");
    }
  };
  return (
    <div
      style={{
        maxWidth: 560,
        padding: "0 15px",
        margin: "auto",
        height: "100%",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Mamo Collagen</h4>
              <p className={classes.cardCategoryWhite}>Please Login</p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "email",
                      onChange: handleInput,
                      value: values.email,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Password"
                    id="password"
                    inputProps={{
                      name: "password",
                      type: showPassword ? "text" : "password",
                      onChange: handleInput,
                      value: values.password,
                      endAdornment: showPassword ? (
                        <Hidden
                          color="inherit"
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowPassword(false)}
                        />
                      ) : (
                        <View
                          color="inherit"
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowPassword(true)}
                        />
                      ),
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={handleLogin}>
                Login
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
