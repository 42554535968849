import axiosInstance from "service/axiosInstance.js";

const getProduct = () =>
  axiosInstance({
    method: "GET",
    url: "product",
  });

const postProduct = (data) =>
  axiosInstance({
    method: "POST",
    url: "product",
    data: {
      name: "Collagen",
      stock: 0,
      image: "testimg",
    },
  });

const patchProduct = (id, stock) =>
  axiosInstance({
    method: "PATCH",
    url: `product/${id}`,
    data: {
      stock,
    },
  });
export { getProduct, postProduct, patchProduct };
