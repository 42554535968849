import axiosInstance from "service/axiosInstance.js";

const getOrder = () =>
  axiosInstance({
    method: "GET",
    url: "order",
  });

const addOrder = ({ quantity }) =>
  axiosInstance({
    method: "POST",
    url: "order",
    data: {
      quantity,
    },
  });

const getOrderInDay = () =>
  axiosInstance({
    method: "GET",
    url: "order/count",
  });

const getOrderByUser = () =>
  axiosInstance({
    method: "GET",
    url: "order/by-user",
  });

export { getOrder, getOrderInDay, getOrderByUser, addOrder };
