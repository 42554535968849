import  React, {useEffect} from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { useAuthContext } from "context/auth-context.js";
import routes from "routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/reactlogo.png";

let ps;

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  const authContext = useAuthContext();
  const hist = useHistory();
  // initialize and destroy the PerfectScrollbar plugin
  // React.useEffect(() => {
  //   if (mainPanel.current) {
  //     if (navigator.platform.indexOf("Win") > -1) {
  //       ps = new PerfectScrollbar(mainPanel.current, {
  //         suppressScrollX: true,
  //         suppressScrollY: false,
  //       });
  //       document.body.style.overflow = "hidden";
  //     }
  //     window.addEventListener("resize", resizeFunction);
  //     // Specify how to clean up after this effect:
  //     return function cleanup() {
  //       if (navigator.platform.indexOf("Win") > -1) {
  //         ps.destroy();
  //       }
  //       window.removeEventListener("resize", resizeFunction);
  //     };
  //   }
  // }, [mainPanel]);

  const checkRoute = () => {
    if (!routes.map((val) => val.path).includes(hist?.location.pathname)) {
      if (!authContext.role) {
        // window.location.reload()
        return <Redirect to="/login" />;
      }
      if (authContext.role === "user") {
        return <Redirect to="/admin/user" />;
      } else {
        return <Redirect to="/admin/dashboard" />;
      }
    }
  };
  const switchRoutes = (authContext) => {
    return (      
      <>
        <Switch>
          {routes.map((prop, key) => {
            if (
              prop.layout === "/admin" &&
              ((prop.role && prop.role === authContext.role) || !prop.role)
            ) {
              return (
                <Route
                  path={prop.layout + prop.path}
                  component={prop.component}
                  key={key}
                />
              );
            }
            return null;
          })}
          <Route>{checkRoute()}</Route>
        </Switch>
      </>
    );
  };
  console.log(authContext)
  return (    
    !authContext.loading &&  
    <>
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={"Creative Tim"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
        />        
        <div className={classes.mainPanel} ref={mainPanel}>        
          <Navbar
            routes={routes}
            handleDrawerToggle={handleDrawerToggle}
            {...rest}
          />
          {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>{switchRoutes(authContext)}</div>
            </div>
          ) : (
            <div className={classes.map}>{switchRoutes(authContext)}</div>
          )}
          {/* {getRoute() ? <Footer /> : null} */}
          {/* <FixedPlugin
            handleImageClick={handleImageClick}
            handleColorClick={handleColorClick}
            bgColor={color}
            bgImage={image}
            handleFixedClick={handleFixedClick}
            fixedClasses={fixedClasses}
          /> */}
      
      </div>          
    </div>
    </>
  );
}
