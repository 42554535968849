import React, { useEffect, useState } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import Fab from "@material-ui/core/Fab";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import View from "@material-ui/icons/Visibility";
import Modal from "@material-ui/core/Modal";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { getOrder, getOrderInDay } from "service/api/order.js";
import { getUsers, getUserInDay, getVerifiedUsers } from "service/api/user.js";
import { getProduct, patchProduct } from "service/api/product.js";
import Button from "components/CustomButtons/Button.js";
import { bugs, website, server } from "variables/general.js";
import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
} from "variables/charts.js";
import { useHistory } from "react-router-dom";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { postProduct } from "service/api/product";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const hist = useHistory();
  const [orderData, setOrderData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [verifiedUserData, setVerifiedUserData] = useState([]);
  const [userCount, setUserCount] = useState(0);
  // const [orderCount, setOrderCount] = useState(0);
  const [stock, setStock] = useState(0);
  const [updatingStock, setUpdatingStock] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [productId, setProductId] = useState(null);
  const [showModal, setShowModal] = useState(true);
  const handleUpdateStock = async () => {
    setUpdatingStock(true);
    await patchProduct(productId, +quantity);
    setUpdatingStock(false);
  };
  const handleEdit = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };
  useEffect(() => {
    const fetchData = async () => {
      const mappedOrder = [];
      const orders = await getOrder();
      orders.data.data.forEach((orders) => {
        const orderRow = [];
        orderRow.push("Collagen");
        orderRow.push(`${orders.user[0].firstName} ${orders.user[0].lastName}`);
        orderRow.push(orders.quantity);
        orderRow.push(format(orders.createdDate));
        orderRow.push("Y");
        mappedOrder.push(orderRow);
      });
      setOrderData(mappedOrder);
    };
    fetchData();
  }, []);
  useEffect(() => {
    if (!updatingStock) {
      const fetchData = async () => {
        try {
          const response = await getProduct();
          if (response.data.data.length) {
            setProductId(response.data.data[0]._id);
            setStock(response.data.data[0].stock);
          } else {
            await postProduct();
          }
        } catch (error) {
          console.log(error);
        }
      };
      fetchData();
      setShowModal(false);
    }
  }, [updatingStock]);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     const response = await getOrderInDay();
  //     setOrderCount(response.data.count);
  //   };
  //   fetchData();
  // }, []);
  useEffect(() => {
    const fetchData = async () => {
      const response = await getUserInDay();
      setUserCount(response.data.count);
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const verifiedUsers = await getVerifiedUsers();
      const mappedVerifiedUser = [];
      verifiedUsers.data.data.forEach((user) => {
        const userRow = [];
        userRow.push(user.saleId);
        userRow.push(`${user.firstName} ${user.lastName}`);
        userRow.push(user.lineId);
        userRow.push(user.phone);
        mappedVerifiedUser.push(userRow);
      });
      setVerifiedUserData(mappedVerifiedUser);
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const users = await getUsers();
      const mappedUser = [];
      users.data.data.forEach((user) => {
        const userRow = [];
        userRow.push(
          <View color="primary" style={{cursor: 'pointer'}} onClick={() => hist.push(`/admin/user/${user._id}`)} />          
        );
        userRow.push(`${user.firstName} ${user.lastName}`);
        userRow.push(user.lineId);
        userRow.push(user.phone);
        mappedUser.push(userRow);
      });
      setUserData(mappedUser);
    };
    fetchData();
  }, []);
  const stockFunc = () => {
    return stock.toLocaleString();
  };
  function format(input) {
    var date = new Date(input);
    return (
      [
        ("0" + date.getDate()).slice(-2),
        ("0" + (date.getMonth() + 1)).slice(-2),
        date.getFullYear(),
      ].join("/") +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes()
    );
  }
  orderData.forEach((order) => console.log(order));
  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>สต๊อกสินค้าคงเหลือ</p>
              <h3 className={classes.cardTitle}>{stockFunc()}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats} style={{ width: "100%" }}>
                <DateRange />
                อัพเดตล่าสุด
                <div
                  style={{ marginLeft: "auto", cursor: "pointer" }}
                  onClick={handleEdit}
                >
                  <Edit />
                </div>
                <Modal
                  open={showModal}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      minHeight: "100%",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        background: "white",
                        maxWidth: 500,
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        // alignItems: "center",
                        padding: 20,
                        borderRadius: 6,
                      }}
                    >
                      <div
                        className=""
                        style={{
                          position: "absolute",
                          top: 20,
                          right: 20,
                          cursor: "pointer",
                        }}
                        onClick={handleClose}
                      >
                        <Close />
                      </div>
                      <CustomInput
                        labelText="จำนวนสต๊อก"
                        id="quantity"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          name: "quantity",
                          type: "number",
                          onChange: (e) => setQuantity(e.target.value),
                        }}
                      />
                      <Button
                        color="primary"
                        onClick={handleUpdateStock}
                        style={{ marginTop: 20 }}
                      >
                        อัพเดต
                      </Button>
                    </div>
                  </div>
                </Modal>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        {/* <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>content_copy</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>จำนวนสินค้าที่ถูกเบิก</p>
              <h3 className={classes.cardTitle}>                
                {orderCount}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>              
                <div className={classes.stats}>
                  <Update />
                  24 ชั่วโมงที่ผ่านมา
                </div>
              </div>
            </CardFooter>
          </Card>
        </GridItem> */}

        {/* <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Fixed Issues</p>
              <h3 className={classes.cardTitle}>75</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <LocalOffer />
                Tracked from Github
              </div>
            </CardFooter>
          </Card>
        </GridItem> */}
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Accessibility />
              </CardIcon>
              <p className={classes.cardCategory}>ตัวแทน</p>
              <h3 className={classes.cardTitle}>+{userCount}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                24 ชั่วโมงที่ผ่านมา
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      {/* <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="success">
              <ChartistGraph
                className="ct-chart"
                data={dailySalesChart.data}
                type="Line"
                options={dailySalesChart.options}
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Daily Sales</h4>
              <p className={classes.cardCategory}>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                </span>{" "}
                increase in today sales.
              </p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> updated 4 minutes ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="warning">
              <ChartistGraph
                className="ct-chart"
                data={emailsSubscriptionChart.data}
                type="Bar"
                options={emailsSubscriptionChart.options}
                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                listener={emailsSubscriptionChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Email Subscriptions</h4>
              <p className={classes.cardCategory}>Last Campaign Performance</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> campaign sent 2 days ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="danger">
              <ChartistGraph
                className="ct-chart"
                data={completedTasksChart.data}
                type="Line"
                options={completedTasksChart.options}
                listener={completedTasksChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Completed Tasks</h4>
              <p className={classes.cardCategory}>Last Campaign Performance</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> campaign sent 2 days ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer> */}
      <GridContainer>
        {/* <GridItem xs={12} sm={12} md={6}>
          <CustomTabs
            title="Tasks:"
            headerColor="primary"
            tabs={[
              {
                tabName: "Bugs",
                tabIcon: BugReport,
                tabContent: (
                  <Tasks
                    checkedIndexes={[0, 3]}
                    tasksIndexes={[0, 1, 2, 3]}
                    tasks={bugs}
                  />
                ),
              },
              {
                tabName: "Website",
                tabIcon: Code,
                tabContent: (
                  <Tasks
                    checkedIndexes={[0]}
                    tasksIndexes={[0, 1]}
                    tasks={website}
                  />
                ),
              },
              {
                tabName: "Server",
                tabIcon: Cloud,
                tabContent: (
                  <Tasks
                    checkedIndexes={[1]}
                    tasksIndexes={[0, 1, 2]}
                    tasks={server}
                  />
                ),
              },
            ]}
          />
        </GridItem> */}
        {/* <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="danger">
              <h4 className={classes.cardTitleWhite}>รายการเบิกสินค้า</h4>
              <p className={classes.cardCategoryWhite}>
                New employees on 15th September, 2016
              </p>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="danger"
                tableHead={[
                  "สินค้า",
                  "เบิกโดย",
                  "จำนวน",
                  "เวลา",
                  "Admin Checked",
                ]}
                tableData={orderData}
              />
            </CardBody>
          </Card>
        </GridItem> */}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info">
              <h4 className={classes.cardTitleWhite}>
                รายชื่อตัวแทนที่ยังไม่ถูกอนุมัติ
              </h4>
              {/* <p className={classes.cardCategoryWhite}>
                New employees on 15th September, 2016
              </p> */}
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="info"
                tableHead={[
                  "ดูข้อมูล",
                  "ชื่อ-นามสกุล",
                  "Line Id",
                  "เบอร์โทรศัพท์",
                ]}
                tableData={userData}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info">
              <h4 className={classes.cardTitleWhite}>รายชื่อตัวแทน</h4>
              {/* <p className={classes.cardCategoryWhite}>
                New employees on 15th September, 2016
              </p> */}
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="info"
                tableHead={[
                  "รหัสตัวแทน",
                  "ชื่อ-นามสกุล",
                  "Line Id",
                  "เบอร์โทรศัพท์",
                ]}
                tableData={verifiedUserData}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
