import axiosInstance from "service/axiosInstance.js";

const getUsers = () =>
  axiosInstance({
    method: "GET",
    url: "user",
  });

const getUserById = (id) =>
  axiosInstance({
    method: "GET",
    url: `user/${id}`,
  });

const getVerifiedUsers = () =>
  axiosInstance({
    method: "GET",
    url: "user",
    params: {
      verified: true,
    },
  });

const getUserInDay = () =>
  axiosInstance({
    method: "GET",
    url: "user/count",
  });

const getProfile = () =>
  axiosInstance({
    method: "GET",
    url: "user/profile",
  });

const updateProfile = (params) => {
  axiosInstance({
    method: "PUT",
    url: "user/profile",
    data: {
      ...params,
    },
  });
};

const approveUser = (id) =>
  axiosInstance({
    method: "PATCH",
    url: `user/${id}`,
  });

const deleteUser = (id) =>
  axiosInstance({
    method: "DELETE",
    url: `user/${id}`,
  });

export {
  getUsers,
  getUserById,
  getVerifiedUsers,
  getUserInDay,
  getProfile,
  updateProfile,
  approveUser,
  deleteUser,
};
