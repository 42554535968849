import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { getProfile, updateProfile } from "service/api/user.js";
import avatar from "assets/img/faces/marc.jpg";
import CustomUploader from "components/Uploader/Uploader.js";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: '"Kanit", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function UserProfile() {
  const classes = useStyles();
  const initialValue = {
    email: "",
    nickName: "",
    firstName: "",
    lastName: "",
    lineId: "",
    facebook: "",
    phone: "",
    address: "",
    province: "",
    postCode: "",
  };
  const [value, setValue] = useState(initialValue);
  const [isLoadingProfile, setIsLoadingProfile] = useState(true);
  const defaultProfile = `${process.env.PUBLIC_URL}/profile-placeholder.png`
  const [profileImg, setProfileImg] = useState(defaultProfile);
  const [src, setSrc] = useState(process.env.PUBLIC_URL + "/logo.jpg");

  function drawImageProp(ctx, img, x, y, w, h, offsetX, offsetY) {

    if (arguments.length === 2) {
        x = y = 0;
        w = ctx.canvas.width;
        h = ctx.canvas.height;
    }

    // default offset is center
    offsetX = typeof offsetX === "number" ? offsetX : 0.5;
    offsetY = typeof offsetY === "number" ? offsetY : 0.5;

    // keep bounds [0.0, 1.0]
    if (offsetX < 0) offsetX = 0;
    if (offsetY < 0) offsetY = 0;
    if (offsetX > 1) offsetX = 1;
    if (offsetY > 1) offsetY = 1;

    var iw = img.width,
        ih = img.height,
        r = Math.min(w / iw, h / ih),
        nw = iw * r,   // new prop. width
        nh = ih * r,   // new prop. height
        cx, cy, cw, ch, ar = 1;

    // decide which gap to fill    
    if (nw < w) ar = w / nw;                             
    if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh;  // updated
    nw *= ar;
    nh *= ar;

    // calc source rectangle
    cw = iw / (nw / w);
    ch = ih / (nh / h);

    cx = (iw - cw) * offsetX;
    cy = (ih - ch) * offsetY;

    // make sure source rectangle is valid
    if (cx < 0) cx = 0;
    if (cy < 0) cy = 0;
    if (cw > iw) cw = iw;
    if (ch > ih) ch = ih;

    // fill image in dest. rectangle
    ctx.fillStyle = 'white'
    ctx.fillRect(cx, cy, cw, ch)
    ctx.drawImage(img, cx, cy, cw, ch,  x, y, w, h);
  }
  function roundedImage(ctx,x,y,width,height,radius){
    ctx.beginPath();
    // ctx.moveTo(x + radius, y);
    // ctx.lineTo(x + width - radius, y);
    // ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    // ctx.lineTo(x + width, y + height - radius);
    // ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    // ctx.lineTo(x + radius, y + height);
    // ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
    // ctx.lineTo(x, y + radius);
    // ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.arc(
      x * 0.5, // x
      y * 0.5, // y
      width * 0.5, // radius
      0, // start angle
      2 * Math.PI // end angle,
    );
    ctx.closePath();
  }
  useEffect(() => {
    if (!isLoadingProfile) {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      const img = new Image();
      const personImg = new Image();
      personImg.src = profileImg
      function fitTextOnCanvas(text,xPosition,yPosition,fontface='Kanit'){    
        var fontsize=70;        
        do{
            fontsize--;
            context.font=fontsize+"px "+fontface;
        } while(context.measureText(text).width>600)
      
          // draw the text
          context.fillText(text,xPosition,yPosition)
      }
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        const fontSize = 70;
        context.drawImage(img, 0, 0, img.width, img.height); 

        context.beginPath()
        const font = `${fontSize}px Arial`;
        context.font = font;
        context.fillStyle = "rgb(30,92,153)"
        // context.fillText(value.saleId, 1000, 505);
        fitTextOnCanvas(value.nickName,970, 535);
        fitTextOnCanvas(value.facebook, 970, 735);
        fitTextOnCanvas(value.lineId, 970, 935);
        fitTextOnCanvas(value.phone, 970, 1135);
        fitTextOnCanvas(value.province, 970, 1335);
        
        if (personImg) {
          roundedImage(context, 816, 807, 628, 628, 370)
          context.clip(); 
          drawImageProp(context, personImg, 93, 90, 628, 628);
          context.restore();
        }
        setSrc(canvas.toDataURL("image/jpeg"));
      };
      img.src = process.env.PUBLIC_URL + "/card.png"
    }
  }, [isLoadingProfile, value]);
 
  useEffect(() => {
    (async () => {
      const response = await getProfile();
      setValue(v => ({
        ...v,
        ...response.data.data,
      }));
      if (response.data.data.img) {
        setProfileImg(response.data.data.img)        
      }
      setIsLoadingProfile(false);
    })();
  }, []);

  const handleUpdate = async () => {
    if (validateForm()) {
      setIsLoadingProfile(true);
      await updateProfile(value);
      setIsLoadingProfile(false);
    }
  };

  const validateForm = () => {
    let isValid = true;
    for (const key in initialValue) {
      console.log(key);
      if (
        !document.querySelector(`#profileForm [name=${key}]`).reportValidity()
      ) {
        isValid = false;
        break;
      }
    }
    return isValid;
  };

  const handleInput = (e) => {
    const fieldName = e.target.name;
    setValue({
      ...value,
      [fieldName]: e.target.value,
    });
  };
  const readFile = (fileObj) => new Promise((
    resolve, reject
  ) => {
    const reader = new FileReader()
    reader.onload = () => {    
      resolve(reader.result)                 
    }
    reader.onerror = (error) => {
      reject(error)
    }
    reader.readAsDataURL(fileObj)
  })
  const showUploadedImg = (img) => {
    setProfileImg(img[0].data)
    setValue(v => ({
      ...v,
      img: img[0].data,
    }));
  }
  return (
    <div>
      <GridContainer id="profileForm">
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>โปรไฟล์</h4>
              <p className={classes.cardCategoryWhite}>
                แก้ไขโปรไฟล์และดาวน์โหลดบัตรตัวแทน
              </p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h5>รูปโปรไฟล์</h5>
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  <div style={{paddingTop: '100%', position: 'relative'}}>
                    <img src={profileImg} style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover'}} />
                  </div>
                </GridItem>
                <GridItem xs={10} sm={10} md={10}>
                  <CustomUploader onSuccess={showUploadedImg} />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="รหัสตัวแทน"
                    id="company-disabled"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      disabled: true,
                      name: "saleId",
                      value: value.saleId || "0000",
                      onChange: handleInput,
                      required: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Email address"
                    id="email-address"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "email",
                      disabled: true,
                      value: value.email,
                      onChange: handleInput,
                      required: true,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="ชื่อเล่น"
                    id="nick-name"
                    inputProps={{
                      name: "nickName",
                      value: value.nickName,
                      onChange: handleInput,
                      required: true,
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="ชื่อ"
                    id="first-name"
                    inputProps={{
                      name: "firstName",
                      value: value.firstName,
                      onChange: handleInput,
                      required: true,
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="นามสกุล"
                    id="last-name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "lastName",
                      value: value.lastName,
                      onChange: handleInput,
                      required: true,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Line ID"
                    id="line"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "lineId",
                      value: value.lineId,
                      onChange: handleInput,
                      required: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Facebook"
                    id="facebook"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "facebook",
                      value: value.facebook,
                      onChange: handleInput,
                      required: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="เบอร์โทรศัพท์"
                    id="phone"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "phone",
                      value: value.phone,
                      onChange: handleInput,
                      required: true,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="ที่อยู่"
                    id="address"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "address",
                      multiline: true,
                      rows: 5,
                      value: value.address,
                      onChange: handleInput,
                      required: true,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="จังหวัด"
                    id="province"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "province",
                      value: value.province,
                      onChange: handleInput,
                      required: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="รหัสไปรษณีย์"
                    id="postal-code"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "postCode",
                      value: value.postCode,
                      onChange: handleInput,
                      required: true,
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={handleUpdate}>
                อัพเดตโปรไฟล์
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card profile>
            {/* <CardAvatar profile>
              <a href="#pablo" onClick={(e) => e.preventDefault()}>
                <img src={avatar} alt="..." />
              </a>
            </CardAvatar> */}
            <CardBody profile>
              <img src={src} style={{ maxWidth: "100%" }} />
              {/* <h6 className={classes.cardCategory}>CEO / CO-FOUNDER</h6>
              <h4 className={classes.cardTitle}>Alec Thompson</h4>
              <p className={classes.description}>
                Don{"'"}t be scared of the truth because we need to restart the
                human foundation in truth And I love you like Kanye loves Kanye
                I love Rick Owens’ bed design but the back is...
              </p> */}
              <a href={src} download>
                <Button color="primary" round>
                  ดาวน์โหลด
                </Button>
              </a>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
