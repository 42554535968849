/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useLayoutEffect, useState } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

// core components
import Admin from "layouts/Admin.js";
// import User from "layouts/User.js";
import Login from "views/Login/Login.js";
import AuthContextProvider, { useAuthContext } from "context/auth-context.js";

import "assets/css/material-dashboard-react.css?v=1.9.0";
import "assets/css/main.scss";
// const token = localStorage.getItem("token");

export const hist = createBrowserHistory();
const App = () => {
  return (
    <Router history={hist}>
      <Switch>
        <Route path="/login" exact={true} component={Login} />
        <Route path="/admin" component={Admin} />        
        <Redirect from="*" exact={true} to="/admin" />
      </Switch>
    </Router>
  );
};

const AppWithAuthContext = () => (
  <AuthContextProvider>
    <App />
  </AuthContextProvider>
);
ReactDOM.render(<AppWithAuthContext />, document.getElementById("root"));
