import axiosInstance from "service/axiosInstance.js";

const signup = ({ email, password, firstName, lastName }) =>
  axiosInstance({
    method: "POST",
    url: "/signup",
    data: { email, password, firstName, lastName },
  });

const signin = ({ email, password }) =>
  axiosInstance({
    method: "POST",
    url: "/signin",
    data: { email, password },
  });

const verify = () =>
  axiosInstance({
    method: "GET",
    url: "/verify",
  });

const signout = () =>
  axiosInstance({
    method: "POST",
    url: "/logout",
  });

export { signup, signin, signout, verify };
