import axiosInstance from "service/axiosInstance.js";

const getStock = () =>
  axiosInstance({
    method: "GET",
    url: "/user/stock",
  });

const updateStock = (stock) =>
  axiosInstance({
    method: "PUT",
    url: "/user/stock",
    data: {
      stock,
    },
  });

export { getStock, updateStock };
