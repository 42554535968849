import React, { useState, useContext, createContext, useEffect } from "react";
import { verify } from "service/api/auth.js";
import { createBrowserHistory } from "history";
import jwt from "jsonwebtoken";

const AuthContext = createContext();
export const hist = createBrowserHistory();

export const useAuthContext = () => useContext(AuthContext);

export default function AuthContextProVider({ children }) {
  const [role, setRole] = useState();
  const [loading, setLoading] = useState(true);
  const checkAuth = async () => {
    try {
      const response = await verify();
      const token = localStorage.getItem("token")
      if (response?.data?.success && token) {
        const { role: userRole } = jwt.decode(token);
        setRole(userRole);
        if (hist.location.pathname === "login") {
          hist.push("/admin/dashboard");
        }
      } else {
        hist.push("/login");
      }
    } catch (error) {
      localStorage.removeItem("token");
      hist.push("/login");
      // window.location.reload()
      // console.log(error);
    } finally {
      setLoading(false)
    }
  };
  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <AuthContext.Provider value={{ role, setRole, loading }}>
      {children}
    </AuthContext.Provider>
  );
}
