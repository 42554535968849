import { useHistory } from "react-router-dom";
import { signout } from "service/api/auth.js";
export default function Logout() {
  const hist = useHistory();
  const handleLogout = () => {
    localStorage.removeItem("token");
    signout();
    hist.push("/login");
    window.location.reload();
  };
  return handleLogout();
}
